import { createContext, useState } from "react";

export const localStoreContext = createContext({
    personsColumns: 0,
    updatePersonsColumns: () => {}
});

const defaultLocalData = {
    meaningButtonsColumns: 10,
    meaningButtonsHeight: 0,
    volume: 0,
    selectedMessagingUserId: null,
    selectedOfficeId: null,
    personsColumns: 3,
    personsHeight: window.innerHeight / 5,
    roomsColumns: 3,
    roomsHeight: window.innerHeight / 5,
    messagesHeight: window.innerHeight / 5,
    patientTrackingHeight: window.innerHeight / 5
};

export default function useLocalStore() {
    const localData = { ...defaultLocalData, ...getLocalStorageData() };

    const [meaningButtonsColumns, setMeaningButtonsColumns] = useState(
        localData.meaningButtonsColumns
    );
    const updateMeaningButtonsColumns = val => {
        setMeaningButtonsColumns(val);
        updateLocalStorageData({ meaningButtonsColumns: val });
    };

    const [patientTrackingHeight, setPatientTrackingHeight] = useState(
        localData.patientTrackingHeight
    );
    const updatePatientTrackingHeight = val => {
        setPatientTrackingHeight(val);
        updateLocalStorageData({ patientTrackingHeight: val });
    };

    const [meaningButtonsHeight, setMeaningButtonsHeight] = useState(
        localData.meaningButtonsHeight
    );
    const updateMeaningButtonsHeight = val => {
        setMeaningButtonsHeight(val);
        updateLocalStorageData({ meaningButtonsHeight: val });
    };

    const [volume, setVolume] = useState(localData.volume);
    const updateVolume = val => {
        setVolume(val);
        updateLocalStorageData({ volume: val });
    };

    const [selectedOfficeId, setSelectedOfficeId] = useState(
        localData.selectedOfficeId
    );
    const updateSelectedOfficeId = id => {
        setSelectedOfficeId(id);
        updateLocalStorageData({ selectedOfficeId: id });
    };

    const [selectedMessagingUserId, setSelectedMessagingUserId] = useState(
        localData.selectedMessagingUserId
    );
    const updateSelectedMessagingUserId = id => {
        setSelectedMessagingUserId(id);
        updateLocalStorageData({ selectedMessagingUserId: id });
    };

    const [personsColumns, setPersonsColumns] = useState(
        localData.personsColumns
    );
    const updatePersonsColumns = num => {
        setPersonsColumns(num);
        updateLocalStorageData({ personsColumns: num });
    };
    const [roomsColumns, setRoomsColumns] = useState(localData.personsColumns);
    const updateRoomsColumns = num => {
        setRoomsColumns(num);
        updateLocalStorageData({ roomsColumns: num });
    };
    const [personsHeight, setPersonsHeight] = useState(localData.personsHeight);
    const updatePersonsHeight = num => {
        setPersonsHeight(num);
        updateLocalStorageData({ personsHeight: num });
    };
    const [roomsHeight, setRoomsHeight] = useState(localData.roomsHeight);

    const updateRoomsHeight = num => {
        setRoomsHeight(num);
        updateLocalStorageData({ roomsHeight: num });
    };
    const [messagesHeight, setMessagesHeight] = useState(
        localData.messagesHeight
    );
    const updateMessagesHeight = num => {
        setMessagesHeight(num);
        updateLocalStorageData({ messagesHeight: num });
    };

    return {
        volume,
        updateVolume,
        selectedMessagingUserId,
        updateSelectedMessagingUserId,
        selectedOfficeId,
        updateSelectedOfficeId,
        personsColumns,
        updatePersonsColumns,
        personsHeight,
        updatePersonsHeight,
        roomsColumns,
        updateRoomsColumns,
        roomsHeight,
        updateRoomsHeight,
        messagesHeight,
        updateMessagesHeight,
        meaningButtonsColumns,
        updateMeaningButtonsColumns,
        meaningButtonsHeight,
        updateMeaningButtonsHeight,
        patientTrackingHeight,
        updatePatientTrackingHeight
    };
}

function getLocalStorageData() {
    const insyncAppJson = window.localStorage.getItem("insyncapp");
    let localData;
    try {
        localData = JSON.parse(insyncAppJson);
    } catch (e) {
        console.error("local storage data not loaded");
    }

    return localData;
}

function updateLocalStorageData(newData) {
    const localStorageData = getLocalStorageData();
    const json = JSON.stringify({ ...localStorageData, ...newData });
    window.localStorage.setItem("insyncapp", json);
}
