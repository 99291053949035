import React, {  } from 'react';
import PropTypes from 'prop-types';
// import * as PropTypes from "prop-types";

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { InputAdornment, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: (props ) => props.width ? props.width:  '100%',
      marginTop: (props ) => props.mt ? props.mt : 0,
      marginBottom: (props) => props.mb ? props.mb : 0,
      marginLeft: (props) => props.ml ? props.ml : 0,
      marginRight: (props) => props.mr ? props.mr : 0,
    },
    text: {
      margin: 0,
      padding: 0,
      color: (props ) => props.textColor ? props.textColor : '#99A0AB',
      textAlign: 'left',
      fontSize: (props ) => props.fontSizeText ? props.fontSizeText : 12,
    },
    input: {
      '& .MuiOutlinedInput-inputAdornedStart': {
        textAlign: (props) => props.textAling ? props.textAling : 'left',
      },
      '& .MuiInputBase-input': {
        textAlign: (props) => props.textAling ? props.textAling : 'left',
      },
    },

  })
);

const CustomTextField = (props) => {
// const CustomTextField: FC<Props> = (props) => {
  const {
    value,
    name,
    type,
    labelText,
    label,
    error,
    helperText,
    maxLength,
    minLength,
    multiline,
    rows,
    iconStart,
    iconEnd,
    placeholder,
    disabled,
    onChangeText,
    
    style,
    required,
    inputRef,
    className,
    variant,
  } = props;
  const classes = useStyles(props);


  return (
    <div className={classes.root}>
      <p className={classes.text}>{labelText}</p>
      <TextField
        // {...props}
        label={label}
        type={type}
        style={style}
        className={className}
        // className={classes.input}
        size="small"
        variant="outlined"
        fullWidth
        value={value}
        onChange={onChangeText}
        inputRef={inputRef}
        variant={variant}
        error={error}
        helperText={error ? helperText : ''}
        multiline={multiline || false}
        rows={rows}
        inputProps={{
          maxLength: maxLength || 255,
          minLength: minLength || 255,
        }}
        name={name}
        placeholder={placeholder}
        InputProps={{
          startAdornment: iconStart && (
            <InputAdornment position="start">{iconStart}</InputAdornment>
          ),
          endAdornment: iconEnd && (
            <InputAdornment position="end">{iconEnd}</InputAdornment>
          ),
        }}
        disabled={disabled || false}
      />
    </div>
  );
};

CustomTextField.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  labelText: PropTypes.string,
  mt:  PropTypes.any,
  mb:  PropTypes.any,
  ml:  PropTypes.any,
  mr:  PropTypes.any,
  width:  PropTypes.any,
  textAling: PropTypes.any,
  textColor: PropTypes.any,
  fontSizeText: PropTypes.any,
  error:  PropTypes.bool,
  helperText: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  iconStart:  PropTypes.any,
  iconEnd:  PropTypes.any,
  placeholder: PropTypes.string,
  disabled:  PropTypes.bool,
  onChangeText: (event) => PropTypes.void,
  style:  PropTypes.any,
  required:  PropTypes.bool,
  inputRef:  PropTypes.any,
  className:  PropTypes.any,
  variant:  PropTypes.string,
  autoComplete:  PropTypes.string,
};

export default CustomTextField;
