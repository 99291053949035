import { createContext } from "react";
import useAuth from "./useAuth";
import useMainRouter from "./useMainRouter";
import useMain from "./useMain";
import useLocalStore from "./useLocalStore";
import useNavigation from "./useNavigation";
import useOfficeList from "./useOfficeList";

export default createContext({
  officeId: null,
  setOfficeId: () => {}
});

export function useGlobalContext() {
  const mainRouter = useMainRouter();
  const authCtx = useAuth(mainRouter);
  const officeList = useOfficeList();
  const mainCtx = useMain();
  const localStore = useLocalStore();

  const navigation = useNavigation({
    mainCtx,
    mainRouter
  });

  return {
    mainRouter,
    authCtx,

    mainCtx,
    localStore,

    navigation,
    officeList
  };
}
