import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const insyncColors = ["#0276fd", "#97cc04", "#ebebeb", "#f4d01", "#8f9491"];

export const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#757ce8",
            main: insyncColors[0],
            dark: "#002884",
            contrastText: "#fff"
        },
        // secondary: {
        //     light: "#757ce8",
        //     main: insyncColors[3],
        //     dark: "#002884",
        //     contrastText: "#fff"
        // }
        success: {
            main: '#4CAF50',
            // main: '#28a745', // success
        },
        background: {
            default: '#FFFFFF',
            paper: '#F7F7FB',
        },
        error: {
            main: '#FF4848',
            // main: '#dc3545', // danger
        },
        warning: {
            main: '#ffc107',
        },
        info: {
            main: '#17a2b8',
        },
        dark: {
            main: '#343a40',
        },
        light: {
            main: '#f8f9fa',
        },
        white: {
            main: '#fff',
        },
        mute: {
            main: '#6c757d',
        },
    }
});


export const colors  = {
    general: {
        hover: '#DFE2F2',
        text: '#B7BECD',
        buttonBg: '#EFF1FF',
        contrastText: '#F0B622',
        contrastBgP: '#FFEEDA',
        contrastBgS: '#C6FFFF',
        bggradient: '#4BDCDC',
        bgBtngradient: '#41CAFC',
        black: '#000000',
        textPrimary: '#112BC4',
        textSecondary: '#FFFFFF',
        textHint: '#188AB6',
        hoverSidebar: '#0E24A6'
    }
  }