// import { createMemoryHistory } from "history";

// export default createMemoryHistory({
//   initialEntries: ["/signin", "/offices", "/office", "/patientsignin/office/:id"],
//   initialIndex: 0
// });

import * as history from 'history';

export default history.createBrowserHistory();
