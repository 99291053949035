import firebase from "firebase/app";
import "firebase/firebase-functions";
import "firebase/firebase-auth";
import "firebase/firebase-firestore";
import "firebase/storage";

import { getFirebaseConfig } from './firebase-config.js';

// eslint-disable-next-line
const app = firebase.initializeApp(getFirebaseConfig());

// app.functions().useFunctionsEmulator("http://localhost:5000");

export default firebase;

window.firebase = firebase;
