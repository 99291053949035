import Typography from '@material-ui/core/Typography';
import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundImage: 'url(assets/bgPrimary.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
}))

function Error404Page() {
    const classes = useStyles()
	return (
		<div className={clsx(classes.root, "flex flex-col flex-1 items-center justify-center p-16")}>
			<div className="max-w-512 text-center">
                <Typography variant="h1" color="primary" className="font-medium mb-16">
                    404
                </Typography>

                <Typography variant="h5" color="primary" className="mb-16">
                    Sorry, we couldn't find the page you are looking for
                </Typography>

				{/* <Link className="font-medium" style={{ color: '#0276fd', fontWeight: 'bold', fontSize: 40 }} to="/office/:id">
                    Back home
				</Link> */}

			</div>
		</div>
	);
}

export default Error404Page;
