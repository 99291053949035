import { createContext, useEffect, useState, useRef } from "react";

export default createContext({
    room: {},
    persons: {},
    displayedPersonId: null
});

export function useTimer() {
    ///////must use a ref

    const pausedRef = useRef(false);
    const onTickFunction = useRef(null);

    useEffect(() => {
        const timerInterval = setInterval(() => {
            if (!pausedRef.current) {
                if (typeof onTickFunction.current === "function") {
                    onTickFunction.current();
                }
            }
        }, 1000);
        return () => {
            clearInterval(timerInterval);
        };
    }, []);

    function pause() {
        pausedRef.current = true;
    }

    function unpause() {
        pausedRef.current = false;
    }

    function onTick(f) {
        onTickFunction.current = f;
    }
    return { pause, unpause, isPaused: pausedRef.current, onTick };
}
