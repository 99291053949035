import React, { useContext } from "react";
import mainRouterHistory from "./routers/mainRouterHistory";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Offices from "./components/pages/Offices/Offices";
import SignIn from "./components/pages/SignIn/SignIn";
import PatientSignIn from "./components/pages/PatientSignIn/PatientSignIn";
import NoMatch from "./components/pages/noMatch";
import GlobalContext, { useGlobalContext } from "./hooks/GlobalContext";
import MainAppBar from "./components/MainAppBar/MainAppBar";
import { CssBaseline } from "@material-ui/core";
import { theme } from "./theme/theme";
import { ThemeProvider } from "@material-ui/styles";

function App() {
    const globalCtx = useGlobalContext();

    return (
        <ThemeProvider theme={theme}>
            <GlobalContext.Provider value={globalCtx}>
                <CssBaseline></CssBaseline>
                <MainAppBar></MainAppBar>
                <Router history={mainRouterHistory}>
                    <Switch>
                        {/* <Route exact path="/signin" component={SignIn}></Route>
                        <Route exact path="/offices" component={Offices}></Route>
                        <Route exact path="/patientsignin" component={PatientSignIn}></Route> */}
                        <Route
                            // exact
                            path="/office/:ids"
                            strict
                            sensitive
                            component={PatientSignIn}
                        ></Route>
                        <Route exact path="*">
                            <Redirect to="/office/default" />
                        </Route>
                        {/* <Route path="/">
                            <NoMatch />
                        </Route> */}
                    </Switch>
                </Router>
            </GlobalContext.Provider>
        </ThemeProvider>
    );
}

export default App;
