import React, { useState, useContext, useEffect, useRef } from "react";
import { isEmpty, isNil, view, update, map, type } from 'ramda';
import { Router, Route, useParams, useLocation, useHistory, Redirect } from "react-router-dom";
import clsx from 'clsx';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input/input'
import {
    Container,
    Paper,
    TextField,
    Grid,
    Button,
    CircularProgress,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogContent,
    Divider,
    Select,
    MenuItem,
    Typography
} from "@material-ui/core";

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';


import { colors } from '../../../theme/theme';
import CustomTextField from '../../CustomTextField';
import { useTimer } from "./TimerContext";

import GlobalContext from "../../../hooks/GlobalContext";
import createVisitMassive from "../../../firebase/httpsCallable/createVisitMassive";
import getCurbsideTracking from "../../../firebase/httpsCallable/getCurbsideTracking";
import officeById from "../../../firebase/httpsCallable/officeById";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
        },
        customTextField: {
            width: '100%',
            '& .MuiOutlinedInput-root': {
                borderRadius: 6,
                height: 56,
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.info.main,
                    borderWidth: 1,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    // borderColor: '#DDE6EA',
                    borderWidth: 1,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.primary.main,
                    borderWidth: 1,
                },
                '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.error.main,
                    borderWidth: 1,
                },
                '&.Mui-disabled': {
                    background: theme.palette.mute.main,
                    color: colors.general.text,
                },

            },
            '& .MuiOutlinedInput-input': {
                fontWeight: 400,
                fontSize: 16,
                '&.Mui-disabled': {
                    color: colors.general.text
                }
            },
            '& .MuiOutlinedInput-root': {
                backgroundColor: '#fff',

                '&:hover': {
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#0276fd',
                        borderWidth: 1
                    }
                }
            },

            '& .MuiFormHelperText-root': {
                color: theme.palette.error.main,
                marginTop: 4,
                fontWeight: 500,
                textAlign: 'right',
            },
            '& .MuiFormLabel-root': {
                color: colors.general.text,
                fontWeight: 400,
                fontSize: 16,
                '&.Mui-focused': {
                    color: theme.palette.primary.main,
                    fontWeight: 500,
                },
                '&.Mui-error': {
                    color: theme.palette.error.main,
                    fontWeight: 500,
                },
            },
            '&:hover .MuiFormLabel-root': {
                color: theme.palette.dark.main
            },

        },
        selected: {
            padding: 0,
            margin: 0,
            // marginTop: 15,
            // width: '100%',
            height: 40,
            backgroundColor: "#fff",

        },
        text: {
            margin: 0,
            padding: 0,
            color: '#000',
            textAlign: 'left',
            fontSize: 15,
        },
        boxInputBox: {

            display: 'flex',
            alignItems: 'center',
            border: '1px solid #c4c4c4',
            borderRadius: 3,
            background: '#fff',
            padding: '0 10px',
            height: 39,

            '&:hover': {
                borderColor: '#0276fd',
                borderWidth: 2,
                padding: '0 9px',
            },
        },
        boxInputPhone: {
            font: 'inherit',
            color: 'currentColor',
            width: '100%',
            border: '1px solid transparent',
            // borderRadius: 3,
            // height: '1.1876em',
            margin: 0,
            display: 'block',
            padding: '5.5px',
            background: '#fff',
            opacity: '0.8',
            fontSize: 16,

            // animationName: 'mui-auto-fill-cancel',
            // letterSpacing: 'inherit',
            // animationDuration: '10ms',
            // webkitTapHighlightColor: 'transparent',

            '&:focus-visible': {
                // outlineColor: '#c4c4c4',
                outline: 'none'
            }
        },
        rootNotFound: {
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            backgroundImage: 'url(assets/bgPrimary.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        },

        inputCustomTime: {
            display: 'flex',
            // marginTop: '8px',
            // marginBottom: '14px',
            // marginRight: '8px',

            '& .MuiFormLabel-root': {
                fontSize: '14px',
                top: '-4px',
                fontWeight: 'bold',
                color: '#000'
            },
            '& .MuiFormLabel-filled': {
                top: '0px',
            },
            '& .MuiInputBase-input': {
                padding: '14px',
                fontSize: ' 14px',
                backgroundColor: '#f5f5f5'
            },
        },
        backToTop: {
            position: 'fixed',
            bottom: 20,
            right: 20,
            fontSize: 30,
            width: 40,
            height: 40,
            backgroundColor: '#0276fd',
            color: 'white',
            cursor: 'pointer',
            borderRadius: '100px',
            border: 'none',
            boxShadow: '0 5px 10px #ccc',

            '&:hover': {
                backgroundColor: '#0276fd',
                color: 'white',
            }
        }

    })
);

export default function (props) {

    const { ids } = useParams();
    // const params = props.match.params;

    const { mainCtx, navigation } = useContext(GlobalContext);
    const { onTick } = useTimer();
    const classes = useStyles();
    // const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState('');
    const [selectedOfficeId, setSelectedOfficeId] = useState('');
    const [selectedOfficeName, setSelectedOfficeName] = useState('');
    // ----------------------------------------
    let timestampNow = moment(new Date()).format("MM-DD-YYYY");
    // ----------------------------------------
    const [isVisible, setIsVisible] = useState(false)
    const [option, setOption] = useState({})
    const scrollRef = useRef(null);
    // ----------------------------------------
    // ----------------------------------------
    const [sending, setSending] = useState(false);
    const modalSecondsRemainingRef = useRef(0);
    const [modalSecondsRemaining, setModalSecondsRemaining] = useState(0);
    const [requestState, setRequestState] = useState("success");

    const [checked, setChecked] = useState(true);
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        numberPatients: 1,
    });
    const [formError, setFormError] = useState({
        firstName: false,
        lastName: false,
        phone: false,
    });
    const select_number = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
    ];
    const [arrayPatient, setArrayPatient] = useState([
        { id: Math.floor(Math.random() * 10000), patientName: '', patientLastName: '', appointmentTime: moment(new Date()).format("MM-DD-YYYY hh:mm a"), arrivalTime: new Date().valueOf(), requiredName: false, requiredLastName: false, requiredTime: false, lateColor: '' }
    ]);

    // ----------------------------------------
    // ----------------------------------------
    useEffect(() => {
        function fetch() {
            setLoading(true)
            officeById({
                officeId: ids,
            }).then(result => {
                setSelectedOfficeId(result.data.officeId)
                setSelectedOfficeName(result.data.officeName)
                setLoadingData('yes')
                setLoading(false)
                getInfoTracking(result.data.officeId)
            }).catch(err => {
                setLoadingData('no')
                setLoading(false)
                // history.push("/")
            });
        }

        fetch();
    }, [])

    const getInfoTracking = (officeId) => {
        getCurbsideTracking({
            officeId: officeId,
        })
            .then((resul) => {
                const data = resul.data && resul.data.visitLate;
                setOption(data)
            })
            .catch(err => {
                setOption({})
            });
    }
    // ----------------------------------------
    // ----------------------------------------
    const toggleVisibility = () => {
        if (window.pageYOffset === (document.documentElement.scrollHeight - document.documentElement.clientHeight)) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility)
        return () => {
            window.removeEventListener('scroll', toggleVisibility)
        }
    }, [])

    // ----------------------------------------
    // ----------------------------------------
    useEffect(() => {
        onTick(decrementModalSeconds);
        return () => {
            onTick(() => { });
        };
    }, []);
    // ----------------------------------------
    // ----------------------------------------

    const scrollToBottom = () => {
        scrollRef.current.scrollIntoView({ behavior: "smooth", block: 'end' });
    }

    const onChangeText = (event) => {
        if (event.target.name === 'phone') {
            const pattern = /^[0-9\b]+$/;
            setForm({ ...form, [event.target.name]: pattern.test(event.target.value) ? event.target.value : '' })
        } else {
            setForm({ ...form, [event.target.name]: event.target.value })
        }
    };

    const onChangeTextSms = (event) => {
        setForm({ ...form, phone: event })
    }

    const handleChange = (event) => {
        setArrayPatient([
            { id: Math.floor(Math.random() * 10000), patientName: '', patientLastName: '', appointmentTime: moment(new Date()).format("MM-DD-YYYY hh:mm a"), arrivalTime: new Date().valueOf(), requiredName: false, requiredLastName: false, requiredTime: false, lateColor: '' }
        ]);
        setForm({ ...form, numberPatients: 1 })
        setChecked(event.target.checked);
    };

    const onChangeNumberPatient = (event) => {

        if (event.target.value > form.numberPatients) {
            const addItem = ((event.target.value) - (arrayPatient.length));

            let arr = [];
            for (let i = 0; i < addItem; i++) {
                arr = arr.concat({ id: Math.floor(Math.random() * 10000), patientName: '', patientLastName: '', appointmentTime: moment(new Date()).format("MM-DD-YYYY hh:mm a"), arrivalTime: new Date().valueOf(), requiredName: false, requiredLastName: false, requiredTime: false, lateColor: '' });
            }
            const newData = arrayPatient.concat(arr)
            setArrayPatient(newData)
            setForm({ ...form, numberPatients: event.target.value })
        } else {

            const subtractItem = ((arrayPatient.length) - (event.target.value))
            // let arr = arrayPatient.splice(subtractItem, arrayPatient.length-1 );
            // setArrayPatient(arrayPatient)
            arrayPatient.length = arrayPatient.length - subtractItem;
            setForm({ ...form, numberPatients: event.target.value })
        }

    };

    const onChangeTextArray = (item) => (event) => {
        const indexArray = arrayPatient.findIndex((t) => t.id === item.id);
        let newDataArray = { ...item, [event.target.name]: event.target.value };
        const newAdd = update(indexArray, newDataArray, arrayPatient)
        setArrayPatient(newAdd)
    };

    const handleChangeDate = (name, item) => (event) => {
        if (!isNil(event.target.value)) {
            const indexArray = arrayPatient.findIndex((t) => t.id === item.id);
            let newDataArray = { ...item, [name]: moment(`${timestampNow} ${event.target.value}`).format('MM-DD-YYYY hh:mm a') };
            const newAdd = update(indexArray, newDataArray, arrayPatient)
            setArrayPatient(newAdd)
        }
    }
    // ----------------------------------------
    // ----------------------------------------
    function decrementModalSeconds() {
        modalSecondsRemainingRef.current -= 1;
        setModalSecondsRemaining(modalSecondsRemainingRef.current);
    }

    function startModal(sec) {
        modalSecondsRemainingRef.current = sec;
        setModalSecondsRemaining(sec);
    }

    function clearFields() {
        setForm({
            firstName: '',
            lastName: '',
            phone: '',
            numberPatients: 1,
        });
        setChecked(true)
        setArrayPatient([
            { id: Math.floor(Math.random() * 10000), patientName: '', patientLastName: '', appointmentTime: moment(new Date()).format("MM-DD-YYYY hh:mm a"), arrivalTime: new Date().valueOf(), requiredName: false, requiredLastName: false, requiredTime: false, lateColor: '' }
        ]);
    }

    function handleSubmit() {

        if (isEmpty(form.firstName) || isEmpty(form.lastName) || isEmpty(form.phone)) {
            setFormError({
                firstName: !form.firstName,
                lastName: !form.lastName,
                phone: !form.phone,
            });

        } else {

            setFormError({
                firstName: false,
                lastName: false,
                phone: false,
            });

            if (checked) {

                const newDataPatient = arrayPatient.map((e, index) => ({ ...e, patientName: index === 0 ? form.firstName : e.patientName, patientLastName: index === 0 ? form.lastName : e.patientLastName }));

                const newData = newDataPatient.map(e => ({ ...e, requiredName: e.patientName === '' ? true : false, requiredLastName: e.patientLastName === '' ? true : false }))
                const isBelowThreshold = (currentValue) => currentValue.requiredName || currentValue.requiredLastName;
                if (newData.some(isBelowThreshold)) {
                    setArrayPatient(newData)
                } else {
                    setSending(true);
                    createVisitMassive({
                        // officeId: mainCtx.officeId,
                        officeId: selectedOfficeId,
                        arrayVisit: newDataPatient.map(e => ({
                            ...e,
                            appointmentTime: option && option.lateArrivals === 'yes' ?
                                e.arrivalTime > moment(e.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf() ? e.arrivalTime : moment(e.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf()
                                : moment(e.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf(),
                            lateColor: option && option.lateArrivals === 'yes' ?
                                e.arrivalTime > moment(e.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf() ? '#df3c3c' : ''
                                : '',
                        })),
                        contactData: form,
                    }).then(result => {
                        setRequestState("success");
                        setSending(false);
                        startModal(2);
                        clearFields();

                    }).catch(err => {
                        setSending(false);
                        setRequestState("error");
                    });
                }

            } else {
                const newData = arrayPatient.map(e => ({ ...e, requiredName: e.patientName === '' ? true : false, requiredLastName: e.patientLastName === '' ? true : false }))
                const isBelowThreshold = (currentValue) => currentValue.requiredName || currentValue.requiredLastName;
                if (newData.some(isBelowThreshold)) {
                    setArrayPatient(newData)
                } else {
                    setSending(true);

                    createVisitMassive({
                        // officeId: mainCtx.officeId,
                        officeId: selectedOfficeId,
                        arrayVisit: newData.map(e => ({
                            ...e,
                            appointmentTime: option && option.lateArrivals === 'yes' ?
                                e.arrivalTime > moment(e.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf() ? e.arrivalTime : moment(e.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf()
                                : moment(e.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf(),
                            lateColor: option && option.lateArrivals === 'yes' ?
                                e.arrivalTime > moment(e.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf() ? '#df3c3c' : ''
                                : '',
                        })),
                        contactData: form,
                    }).then(result => {

                        setRequestState("success");
                        setSending(false);
                        startModal(2);
                        clearFields();

                    }).catch(err => {
                        setSending(false);
                        setRequestState("error");
                    });

                }
            }
        }
    }
    // ----------------------------------------
    // ----------------------------------------

    return (
        <Grid
            container
            style={{ minHeight: "80vh" }}
            justify="center"
            alignItems="center"
        >
            {!isEmpty(selectedOfficeName) && loadingData === 'yes' && (
                <div>
                    <p style={{ fontSize: 24, fontWeight: 900, color: '#197FE8', textAlign: 'left', paddingLeft: '20px', width: '100%' }} >{selectedOfficeName}</p>

                    <Paper style={{ height: "100%", padding: "20px", backgroundColor: '#f9faff' }}>
                        {!sending && (
                            <>
                                <p style={{ fontSize: 24, fontWeight: 700, color: '#0D0D0D', marginTop: 0 }} >Contact info</p>
                                <Grid
                                    container
                                    spacing={1}
                                    justify="center"
                                    style={{ textAlign: "center" }}
                                >
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <CustomTextField
                                            // label="Name"
                                            placeholder="Contact name"
                                            labelText="First Name"
                                            variant="outlined" // outlined, filled, 
                                            className={classes.customTextField}
                                            mt={0}
                                            mb={0}
                                            mr={5}
                                            textColor="#000"
                                            fontSizeText="15"
                                            name="firstName"
                                            id="firstName"
                                            value={form.firstName}
                                            error={formError.firstName}
                                            helperText="required"
                                            // required={true}
                                            onChangeText={onChangeText}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <CustomTextField
                                            // label="Name"
                                            placeholder="Contact last name"
                                            labelText="Last Name"
                                            variant="outlined" // outlined, filled, 
                                            className={classes.customTextField}
                                            mt={0}
                                            mb={0}
                                            mr={5}
                                            textColor="#000"
                                            fontSizeText="15"
                                            name="lastName"
                                            id="lastName"
                                            value={form.lastName}
                                            error={formError.lastName}
                                            helperText="required"
                                            // required={true}
                                            onChangeText={onChangeText}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        {/*  validate the number from which country it is */}
                                        <p className={classes.text}>Cell Phone</p>
                                        <div className={classes.boxInputBox}>
                                        <span style={{ fontSize: 15, color: '#000' }}>+1</span>
                                        <PhoneInput
                                            country="US"
                                            placeholder="(000) 000-0000"
                                            value={form.phone}
                                            onChange={onChangeTextSms}
                                            maxLength={14}
                                            className={classes.boxInputPhone}
                                            style={{ border: formError.phone ? '1px solid #FF4848' : '' }}
                                        />
                                        {/* <FormControlLabel
                                                value="start"
                                                control={<Checkbox icon={<BookmarkBorderIcon />} checkedIcon={<BookmarkOutlinedIcon />} color="primary" checked={form.favorite === 'phone'} onChange={handleFavorite('phone')} />}
                                                // label={<Typography style={{ fontWeight: checked ? 700 : 100 }}></Typography>}
                                                labelPlacement="end"
                                                style={{ margin: 0, height: 20 }}
                                            /> */}
                                        </div>
                                        {formError.phone ? <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>required</div> : null}
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <CustomTextField
                                            // label="Name"
                                            placeholder="Contact E-Mail"
                                            labelText="Email Address"
                                            variant="outlined" // outlined, filled, 
                                            className={classes.customTextField}
                                            mt={0}
                                            mb={0}
                                            mr={5}
                                            fontSizeText="15"
                                            textColor="#000"
                                            name="email"
                                            id="email"
                                            value={form.email}
                                            error={formError.email || validateEmail}
                                            helperText={formError.email ? "required" : validateEmail ? 'Please enter a valid email' : null}
                                            // required={true}
                                            onChangeText={onChangeText}
                                            iconEnd={
                                                <FormControlLabel
                                                    value="start"
                                                    control={<Checkbox icon={<BookmarkBorderIcon />} checkedIcon={<BookmarkOutlinedIcon />} color="primary" checked={form.favorite === 'email'} onChange={handleFavorite('email')} />}
                                                    // label={<Typography style={{ fontWeight: checked ? 700 : 100 }}></Typography>}
                                                    labelPlacement="end"
                                                    style={{ margin: 0, height: 20 }}
                                                />
                                            }
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ width: '100%', textAlign: 'left', cursor: 'pointer', padding: 18, borderRadius: 8, backgroundColor: '#f2f4f9' }}>
                                            <FormControlLabel
                                                value="start"
                                                control={<Checkbox color="primary" checked={checked} onChange={handleChange} />}
                                                label={<Typography style={{ fontWeight: checked ? 700 : 100 }}>The contact person is patient</Typography>}
                                                labelPlacement="end"
                                                style={{ margin: 0, height: 20 }}
                                            />
                                        </div>
                                    </Grid>

                                    <Divider style={{ width: '100%', margin: 15 }} />

                                    <div style={{ width: '100%', textAlign: 'left', padding: "10px" }}>
                                        <p style={{ fontSize: 24, marginTop: 0, fontWeight: 700, color: '#0D0D0D' }} >Appointment Info</p>

                                        <div>
                                            <p className={classes.text}>Select number of patients</p>
                                            <div className={classes.boxCustomSchedule}>
                                                <div className={classes.boxOccurr}>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={form.numberPatients}
                                                        variant="outlined"
                                                        className={classes.selected}
                                                        onChange={onChangeNumberPatient}>
                                                        {
                                                            select_number.map(t => {
                                                                return (
                                                                    <MenuItem
                                                                        key={t.value}
                                                                        value={t.value}
                                                                    >
                                                                        {t.label}
                                                                    </MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        {arrayPatient.map((item, index) => {
                                            return (
                                                <Paper key={index} style={{ width: "100%", padding: "20px", backgroundColor: '#f2f4f9', marginBottom: '4%' }}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        justify="center"
                                                        style={{ textAlign: "center" }}

                                                    >
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <p className={classes.text}>Appointment Time</p>
                                                            <TextField
                                                                className={classes.inputCustomTime}
                                                                type="time"
                                                                value={moment(item.appointmentTime.toString(), 'MM-DD-YYYY hh:mm a').format('HH:mm')}
                                                                onChange={handleChangeDate('appointmentTime', item)}
                                                                variant="outlined"
                                                                fullWidth
                                                                autoComplete='off'
                                                                error={item.requiredTime}
                                                            // defaultValue="07:30"
                                                            // id="time"
                                                            // label="Alarm clock"
                                                            />
                                                            {item.requiredTime ? <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>required</div> : null}
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ opacity: checked && index === 0 ? '0.5' : 1, pointerEvents: checked && index === 0 ? 'none' : '' }}>
                                                            <CustomTextField
                                                                // label="Name"
                                                                placeholder="Patient name"
                                                                labelText="First Name"
                                                                variant="outlined" // outlined, filled, 
                                                                className={classes.customTextField}
                                                                mt={0}
                                                                mb={0}
                                                                mr={5}
                                                                fontSizeText="15"
                                                                textColor="#000"
                                                                name={`patientName`}
                                                                id={`name-${index}`}
                                                                value={checked && index === 0 ? form.firstName : item.patientName}
                                                                error={item.requiredName}
                                                                helperText="required"
                                                                // required={true}
                                                                onChangeText={onChangeTextArray(item)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ opacity: checked && index === 0 ? '0.5' : 1, pointerEvents: checked && index === 0 ? 'none' : '' }}>
                                                            <CustomTextField
                                                                // label="Name"
                                                                placeholder="Patient last name"
                                                                labelText="Last Name"
                                                                variant="outlined" // outlined, filled, 
                                                                className={classes.customTextField}
                                                                mt={0}
                                                                mb={0}
                                                                mr={5}
                                                                fontSizeText="15"
                                                                textColor="#000"
                                                                name={`patientLastName`}
                                                                id={`lastName-${index}`}
                                                                value={checked && index === 0 ? form.lastName : item.patientLastName}
                                                                error={item.requiredLastName}
                                                                helperText="required"
                                                                // required={true}
                                                                onChangeText={onChangeTextArray(item)}
                                                            />
                                                        </Grid>

                                                    </Grid>
                                                </Paper>
                                            )
                                        })}
                                    </div>

                                    <Grid item xs={12}>
                                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                                            Submit
                                        </Button>
                                    </Grid>

                                </Grid>
                            </>
                        )}
                        {sending && <CircularProgress></CircularProgress>}
                    </Paper>
                </div>

            )}

            {isEmpty(selectedOfficeName) && loadingData === 'no' && (

                <div className={clsx(classes.rootNotFound, "flex flex-col flex-1 items-center justify-center p-16")}>
                    <div className="max-w-512 text-center">
                        <Typography variant="h1" color="primary" className="font-medium mb-16">
                            404
                        </Typography>

                        <Typography variant="h5" color="primary" className="mb-16">
                            Sorry we couldn't find the office you are looking for
                        </Typography>
                    </div>
                </div>
            )}

            <div className="fixed bottom-2 right-2" ref={scrollRef}>
                <button
                    type="button"
                    onClick={scrollToBottom}
                    className={classes.backToTop}
                    style={{ opacity: isVisible ? 0 : 1 }}
                >
                    <ArrowDownwardIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>

            <Dialog open={modalSecondsRemaining > 0}>
                <DialogContent>
                    {requestState === "success" && (
                        <DoneIcon
                            style={{ fontSize: "50px", color: "green" }}
                        ></DoneIcon>
                    )}
                    {requestState === "error" && (
                        <ErrorIcon
                            style={{ fontSize: "50px", color: "green" }}
                        ></ErrorIcon>
                    )}
                </DialogContent>
            </Dialog>
            <Dialog open={loading}>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <CircularProgress size={50} />
                        Loading ...
                    </div>
                </DialogContent>
            </Dialog>
        </Grid >
    );
}
